// import { Fancybox } from "@fancyapps/ui";
import { gsap, Sine, Power0, Power1, Power2, Power3, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger);

import Splide from '@splidejs/splide';


// matchMedia
// =======================================================
const mediaQuery_md = window.matchMedia("(min-width: 768px)");


// FUNCTION
// =======================================================
function isInPage(node) {
  return (node === document.body) ? false : document.body.contains(node);
}

function closest(node, selector) {
  return (
    node.closest ||
    function (_selector) {
      do {
        if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
          return node;
        }
        node = node.parentElement || node.parentNode;
      } while (node !== null && node.nodeType === 1);
      return null;
    }
  ).call(node, selector);
}

// DRAWER
// =======================================================

const menuBtn = document.querySelector('.l-drawerBtn');
const drawer = document.querySelector('.l-drawer');
const drawerLinks = document.querySelectorAll('.l-drawer a');

var tlDrawer = gsap.timeline({
  paused: true,
  onReverseComplete: function () {
    menuBtn.classList.remove("is-open");
  },
});

tlDrawer
  .to( drawer ,{
    duration: 0.2, autoAlpha: 1, pointerEvents: "auto", ease: Power4.ease
  });

tlDrawer.reverse();

menuBtn.addEventListener('click', function (e) {
  e.preventDefault();
  menuBtn.classList.add("is-open");
  tlDrawer.reversed(!tlDrawer.reversed());
});

drawerLinks.forEach( link => {
  link.addEventListener('click', function (e) {
    tlDrawer.reversed(!tlDrawer.reversed());
  });
}); 

// ANCHOR
// =======================================================

document.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const headerHeight = document.querySelector(".l-header").clientHeight;
    setTimeout(() => {
      // window.scrollBy(0, - headerHeight*2);
    },100)
  }
})

function findHashtags(searchText) {
  const regexp = /\B#\w\w+\b/g;
  const result = searchText.match(regexp);
  if (result) {
    return result;
  } else {
    return [];
  }
}

const h = document.querySelector('.l-header');

gsap.utils.toArray('a[href*="#"]').forEach(function(a) {
  a.addEventListener("click", function(e) {

    const href = e.target.getAttribute("href");
    var currentPage = location.href.split('#')[0];
    var path = new URL(href, currentPage);
    var nextPage = path.href.split('#')[0];
    var header = document.querySelector('.l-header');

    
    if(currentPage == nextPage ) {
      const hashTag = findHashtags(e.target.getAttribute("href"));
      const targetElm = document.querySelector(hashTag[0]);
  
      if(isInPage(targetElm)){
        e.preventDefault();
        gsap.to( window, {
          duration: 1,
          ease: 'power4.out',
          scrollTo: {
            y: targetElm,
            autoKill: false,
            offsetY: header.offsetHeight, //ヘッダーの高さをセット
          }
        });
      }
    }

  });
});


// Splide
// =======================================================

const carouselInterviews = document.querySelectorAll(".c-carousel__interview");
if(carouselInterviews) {
  const interviewOptions = {
    type   : 'loop',
    rewind : true,
    speed  : 1000,
    perPage : 1,
    autoplay : true,
    arrows : false,
    interval: 5000,
    pagination: false,
    pauseOnHover : false,
    updateOnMove: true,
    gap: "1rem",
    mediaQuery: 'min',
    breakpoints: {
      768: {
        gap: "2rem",
      },
      1024: {
        gap: "3rem",
      }
    },    
  }
  carouselInterviews.forEach( carouselInterview => {
    const interviewCarousel = new Splide(carouselInterview, interviewOptions);
   
    interviewCarousel.mount();
  });
}

const carouselTeachers = document.querySelectorAll(".c-carousel__teachers");
if(carouselTeachers) {
  const teachersOptions = {
    type   : 'loop',
    rewind : true,
    speed  : 700,
    perPage : 1,
    autoplay : true,
    arrows : false,
    interval: 3000,
    pagination: true,
    pauseOnHover : false,
    updateOnMove: true,
    // gap: "1rem",
    mediaQuery: 'min',
    breakpoints: {
      768: {
        // gap: "2rem",
      },
      1024: {
        // gap: "3rem",
      }
    },    
  }
  carouselTeachers.forEach( carouselTeacher => {
    const teachersCarousel = new Splide(carouselTeacher, teachersOptions);
   
    teachersCarousel.mount();
  });
}


// COMMON- Accordion
// =======================================================

const accordions = document.querySelectorAll("[data-ac]");
accordions.forEach( accordion => {
  const trigger = accordion.querySelector('[data-ac-trigger]');
  const content = accordion.querySelector('[data-ac-content]');
  trigger.addEventListener('click', function(){
    gsap.to(content, {
      height: (accordion.classList.contains("is-open")) ? 0 : "auto",
      duration: 0.25,
      ease: "power2.inOut"
    });
    trigger.classList.toggle("is-open");
    accordion.classList.toggle("is-open");
  })
});

// COMMON - Scroll Up
// =======================================================

// Scroll Up
const topBtn = document.querySelector('.c-btn__top');
if( isInPage(topBtn) ) {

  topBtn.addEventListener("click", function(e){
    e.preventDefault();
    gsap.to(window, { duration: 0.3, scrollTo: { y: 0, autoKill: false}, ease: 'power2' });
  });

  const fadeInTopBtn = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      start: innerHeight/2 + ' top',
      onEnter: () => {
        gsap.to(topBtn, { autoAlpha: 1, duration: 0.4, ease: Sine.easeOut });
      },
      onLeaveBack: () => {
        gsap.to(topBtn, { autoAlpha: 0, duration: 0.4, ease: Sine.easeOut });
      },
    }
  });
}

// COMMON - Modal
// =======================================================

const modalTriggers = document.querySelectorAll("[data-trigger]");
const modalCloseBtn = document.querySelectorAll("[data-close]");
const modalJumpBtn = document.querySelectorAll("[data-jump]");

const modals = document.querySelectorAll("[data-modal]");
const modalContents = document.querySelectorAll("[data-modal-content]");

let delayTime = 0;
let posY = 40;

gsap.set(modals, { opacity: 0 });
gsap.set(modalContents, { opacity: 0, y: posY });

modalTriggers.forEach( trigger => {
  trigger.addEventListener('click', function(e){
    e.preventDefault();
    var target = this.dataset.trigger;
    modalOpen(target);
    
    document.body.classList.add("no-scroll");
  })
});

modalCloseBtn.forEach( btn => {
  btn.addEventListener('click', function(e){
    e.preventDefault();
    var target = closest(e.currentTarget, "[data-modal]");
    modalClose(target);
  })
});

modals.forEach( modal => {
  modal.addEventListener('click', function(e){
    if(event.target.closest('.c-interview') === null) {
      var target = closest(e.currentTarget, "[data-modal]");
      modalClose(target);
    }
  })
});

modalJumpBtn.forEach( jump => {
  jump.addEventListener('click', function(e){
    var target = this.dataset.jump;
    gsap.set(modals, { opacity: 0 });
    gsap.set(modalContents, { opacity: 0, y: posY });
    modalOpen(target);
  })
});

function modalOpen(target) {
  modals.forEach( elm => {
    elm.classList.remove("is-open");
  });
  
  var modal = document.getElementById(target);
  var modalContent = modal.querySelector("[data-modal-content]");

  gsap.to(modal, {
    duration: 0,
    scrollTo: {
      y: 0,
      autoKill: false,
    }
  });
  gsap.to(modal, {
    opacity: 1,
    duration: 0.4,
    pointerEvents: "auto",
    ease: 'Sine.easeOut'
  });
  gsap.to(modalContent, {
    opacity: 1,
    y: 0,
    delay: delayTime,
    duration: 0.5,
    ease: Sine.easeOut,
    onComplete: function () {
    },
  });
  modal.classList.add("is-open");
  document.body.classList.add("no-scroll");
}

function modalClose(target) {

  var modal = target;
  var modalContent = modal.querySelector("[data-modal-content]");
  gsap.to(modalContent, {
    opacity: 0,
    y: posY,
    duration: delayTime,
    ease: Sine.easeOut,
    onComplete: function () {
    },
  });
  gsap.to(modal, {
    opacity: 0,
    duration: 0.4,
    delay: delayTime,
    pointerEvents: "none",
    onComplete: function() {
      modal.classList.remove("is-open");
    },
    ease: Sine.easeOut,
  });
  document.body.classList.remove("no-scroll");
}


// FORM - Flexible Textarea 
// =======================================================

const flexibleTextArea = function(el){
  const textarea = el.target;
  textarea.style.height = 0
  textarea.style.height = textarea.scrollHeight+"px"
  if (textarea.offsetHeight < 40) {
    textarea.style.height = 40 + "px";
  }
}

const textareas = document.querySelectorAll("textarea");
textareas.forEach( textarea => {
  let lineHeight = Number(textarea.rows);
  while (textarea.scrollHeight > textarea.offsetHeight){
    lineHeight++;
    textarea.rows = lineHeight;
  }

  textarea.addEventListener('input', flexibleTextArea, false);
});



// MAIL
// =======================================================

const mail = document.querySelectorAll('[data-mail]');

function converter(M){
  var str="", str_as="";
  for(var i=0;i<M.length;i++){
    str_as = M.charCodeAt(i);
    str += String.fromCharCode(str_as + 1);
  }
  return str;
}
var ad = converter(String.fromCharCode(104,109,101,110,63,116,111,44,100,99)+String.fromCharCode(116,45,98,110,108));

mail.forEach( element => {
  element.href = "mailto:" + ad;
  element.textContent = ad;
});


// HERO - TOP
// =======================================================

window.addEventListener('load', function(){
  const hero = document.querySelector(".hero");


  if( isInPage(hero) ) {

    const heroClip = hero.querySelectorAll(".hero__copy--blank__clip");
    heroClip.forEach( elm => {
      elm.innerHTML = '<span class="clip" style="display:inline-block;width:100%;heigth:100%;clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);">' + elm.innerHTML + '</span>';
    });
  
    const clipElements = hero.querySelectorAll(".clip");

    const tlhero = gsap.timeline({repeat: -1});
    tlhero.pause();

    const elm01 = hero.querySelector(".hero__titleWrap");

    function initPos(elm) {
      gsap.set(elm, { opacity: 0, clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)' });
    }

    const delay = 1;
    tlhero
    .to(clipElements[0], { delay: delay, duration: 0.5, opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', ease: Sine.easeOut } ,"-=0")
    .to(clipElements[0], { delay: delay*2, duration: 0.8, opacity: 0, onComplete: initPos(clipElements[0]), ease: Sine.easeOut } )
    .to(clipElements[1], { delay: delay, duration: 0.5, opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', ease: Sine.easeOut } ,"-=0")
    .to(clipElements[1], { delay: delay*2, duration: 0.8, opacity: 0, onComplete: initPos(clipElements[0]), ease: Sine.easeOut } )
    .to(clipElements[2], { delay: delay, duration: 0.5, opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', ease: Sine.easeOut } ,"-=0")
    .to(clipElements[2], { delay: delay*2, duration: 0.8, opacity: 0, onComplete: initPos(clipElements[0]), ease: Sine.easeOut } )
    ;

    gsap.to(elm01, {
      duration: 1,
      delay:.5,
      opacity: 1,
      y: 0,
      ease: Sine.easeOut,
      onComplete: function() {
        tlhero.play();
        }
      }
    );

  }
});

// PARALLAX
// =======================================================

//指定した範囲の中でマウス移動に視差効果を付ける
cursorParallax();
function cursorParallax() {
  const targets = document.querySelectorAll('[data-parallax-body]');
  //要素が存在しない場合は処理しない
  if(targets.length === 0) {
    return;
  }

  //パララックス効果の強度を制御（画像の拡大率やスピードに合わせて調整する）
  //値が大きいほどパララックス効果は弱くなる
  const PARALLAX_FACTOR = 20;

  /**
   * 正規化（任意の範囲を、0から1の値に変換する）する関数
   * @param {number} value 対象の値
   * @param {number} min 下限値
   * @param {number} max 上限値
   */
  function norm (value, min, max) {
    return (value - min) / (max - min);
  }

  /**
   * 線形補間（範囲と割合を渡すと、その割合の位置にある値を返す）
   * @param {number} min 下限値
   * @param {number} max 上限値
   * @param {number} ratio 割合
   */
  function lerp (min, max, ratio) {
    return min + (max - min) * ratio;
  }

  targets.forEach(function(target) {
    //要素が存在しない場合は処理しない
    const back = target.querySelector('[data-parallax-back]');
    if(!back) {
      return;
    }

    const front = target.querySelector('[data-parallax-front]');
    if(!front) {
      return;
    }

    const backStyle = back.style;
    const frontStyle = front.style;
    //マウスがパララックスエリアに入った時のイベント
    let areaX,
        areaY,
        areaWidth,
        areaHeight;

    let xCenterBackItem,
        yCenterBackItem,
        backItemWidth,
        backItemHeight;

    let xCenterFrontItem,
        yCenterFrontItem,
        frontItemWidth,
        frontItemHeight;
        
    //ホバー時の処理
    target.addEventListener('mouseenter', function() {
      //動かすエリアの位置・サイズを取得
      const targetRect = target.getBoundingClientRect();
      areaX = targetRect.left;
      areaY = targetRect.top;

      areaWidth = targetRect.width;
      areaHeight = targetRect.height;

      //動かす要素のサイズを取得
      const backRect = back.getBoundingClientRect();
      backItemWidth = backRect.width;
      backItemHeight = backRect.height;

      const frontRect = front.getBoundingClientRect();
      frontItemWidth = frontRect.width;
      frontItemHeight = frontRect.height;

      //動かす要素の中心位置を取得
      xCenterBackItem = backItemWidth/2;
      yCenterBackItem = backItemHeight/2;

      xCenterFrontItem = frontItemWidth/2;
      yCenterFrontItem = frontItemHeight/2;
    });

    //ホバー＋移動中の処理
    target.addEventListener('mousemove', function(e) {
      //表示エリア内でのマウス座標を取得し動かす要素をどの程度移動させれば良いか計算
      const mouseX = e.clientX - areaX;
      const xNorm = norm(mouseX, 0, areaWidth);
      const b_x = (xCenterBackItem - lerp(0, backItemWidth, xNorm)) / PARALLAX_FACTOR;
      const f_x = (xCenterFrontItem - lerp(0, frontItemWidth, xNorm)) / PARALLAX_FACTOR;

      const mouseY = e.clientY - areaY;
      const yNorm = norm(mouseY, 0, areaHeight);
      const b_y = (yCenterBackItem - lerp(0, backItemHeight, yNorm)) / PARALLAX_FACTOR;
      const f_y = (yCenterFrontItem - lerp(0, frontItemHeight, xNorm)) / PARALLAX_FACTOR;

      //パララックスさせる要素にstyleの指定
      backStyle.transform = `translate3d(${-b_x}px, ${-b_y}px,0)`;
      frontStyle.transform = `translate3d(${-f_x}px, 0,0)`;
    });

    //ホバー終了時にリセット
    target.addEventListener('mouseleave', function() {
      backStyle.transform = '';
    });
  });
}


// SCROLL TRIGGER
// =======================================================


const hrader = document.querySelector('.l-header');
gsap.to(".l-header", {
  scrollTrigger: {
    toggleActions: "play none none reverse",
    trigger: "body",
    start: "top top",
    toggleClass: {
      targets: ".l-header",
      className: "is-scroll",
    },    
  },
});

// CREATE ELEMENTS
const clipElements = document.querySelectorAll("[data-clip]");
clipElements.forEach( elm => {
  elm.innerHTML = '<span class="clip" style="display:inline-block;position:relative;"><span class="clip-text" style="display:inline-block; clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); width:100%; height:100%;">' + elm.textContent + '</span></span>';
});



// ANIMATION

const clipAnimElements = document.querySelectorAll('[data-anim="clip"]');
clipAnimElements.forEach( element => {
  const dunaiton = element.dataset.dunaiton;
  const fill = element.querySelectorAll(".clip-fill");
  const text = element.querySelectorAll(".clip-text");

  const customAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    },
  });

  customAnimation
    .to(text, {
      duration: dunaiton,
      opacity:1,
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      stagger: {
        amount: 0.3,
      },
      ease: Sine.easeOut,
    });      
});



const typeAnimElements = document.querySelectorAll('[data-anim="type"]');

typeAnimElements.forEach( element => {
  const name =  element.querySelector(".types__box--name");
  const copy =  element.querySelectorAll(".clip-text");

  const typeAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom bottom",
    },
  });

  typeAnimation
    .to( element, {
      duration: 0.7,
      autoAlpha: 1,
      ease: Sine.easeInOut,
    })
    .to( name, {
      duration: 0.4,
      autoAlpha: 1,
      ease: Sine.easeInOut,
    })
    .to(copy, {
      duration: 0.35,
      opacity:1,
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      stagger: {
        amount: 0.3,
      },
      ease: Sine.easeOut,
    }, "-=0.6");      

});


const fadeInAnimElements = document.querySelectorAll('[data-anim="fadeIn"]');
fadeInAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    autoAlpha: 1,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});



const zoomInAnimElements = document.querySelectorAll('[data-anim="zoomIn"]');
zoomInAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 1,
    scale: 1,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});

const zoomInBlurAnimElements = document.querySelectorAll('[data-anim="zoomInBlur"]');
zoomInBlurAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    scale: 1,
    filter: "blur(0px)",
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});

const blurAnimElements = document.querySelectorAll('[data-anim="blur"]');
blurAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    filter: "blur(0px)",
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 60%",
      end: "bottom bottom",
    }
  })  
});


const fadeInRightAnimElements = document.querySelectorAll('[data-anim="fadeInRight"]');

fadeInRightAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 1,
    autoAlpha: 1,
    x: 0,
    ease: "power1.out",
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom bottom",
    }
  })  
});

const fadeInUpAnimElements = document.querySelectorAll('[data-anim="fadeInUp"]');

fadeInUpAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.8,
    autoAlpha: 1,
    y: 0,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom bottom",
    }
  })  
});


gsap.to( ".culture-lists .list", {
  duration: 0.4,
  autoAlpha: 1,
  y: 0,
  stagger: {
    amount: 0.8
  },
  ease: Sine.easeInOut,
  scrollTrigger: {
    trigger: ".culture-lists",
    start: "top 75%",
    end: "bottom bottom",
  }
});

gsap.to( ".welfare-lists .list", {
  duration: 0.6,
  autoAlpha: 1,
  stagger: {
    amount: 0.8
  },
  ease: Sine.easeInOut,
  scrollTrigger: {
    trigger: ".welfare-lists",
    start: "top 75%",
    end: "bottom bottom",
  }
});
